<template>
  <section class="content">
    <div class="card card-info">
      <div class="card-header">
        <h3 class="card-title">Approval Proposal & LPJ</h3>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover" ref="tblproposal">
            <thead>
              <tr>
                <th>NAMA KEGIATAN</th>
                <th>TANGGAL MULAI</th>
                <th>TANGGAL SELESAI</th>
                <th>STATUS</th>
                <th>TINDAKAN</th>
                <th>TINDAKAN PJ</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
export default {
  nama: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      role_id: "",
      submit: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "status1") {
          this.submit = 2;
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch(
                "/pr/proposal_lpj_kegiatan/approval/" + e.target.dataset.id,
                {
                  method: "PUT",
                  body: "status=" + this.submit,
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        if (e.target.dataset.action == "lpj1") {
          this.submit = 1;
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch(
                "/pr/proposal_lpj_kegiatan/approval_lpj/" + e.target.dataset.id,
                {
                  method: "PUT",
                  body: "status=" + this.submit,
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        if (e.target.dataset.action == "lpj2") {
          this.submit = 100;
          Swal.fire({
            title: "Tolak?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch(
                "/pr/proposal_lpj_kegiatan/approval_lpj/" + e.target.dataset.id,
                {
                  method: "PUT",
                  body: "status=" + this.submit,
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    this.tbl = createTable(e.tblproposal, {
      title: "",
      roles: this.$route.params.roles,
      selectedRowClass: "",
      serverSide: true,
      frame: false,
      displayLength: 100,
      lengthChange: false,
      ajax: "/pr/proposal_lpj_kegiatan",
      columns: [
        { data: "nama_kegiatan" },
        { data: "proposal_tanggal_mulai" },
        { data: "proposal_tanggal_selesai" },
        { data: "proposal_status" },
        { data: "proposal_status" },
        { data: "count_pj" },
      ],
      filterBy: [0, 2],
      rowCallback: function (row, data) {
        let dropdown1 =
          `<div class="btn-group">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  </button>
  <div class="dropdown-menu dropdown-menu-right">
  <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="status1" data-id="` +
          data.id +
          `"> Ongoing kegiatan </button>
  </div>
</div>`;
        $("td:eq(4)", row).html(dropdown1);

        if (data.proposal_status == 0) {
          $("td:eq(3)", row).html(
            `<h6 style="color:#FFCC29;">PERSIAPAN APPROVAL</h6>`
          );
        } else if (data.proposal_status == 1) {
          $("td:eq(3)", row).html(
            `<h6 style="color:#FFA450;">REQUEST APPROVAL</h6>`
          );
        } else if (data.proposal_status == 2) {
          $("td:eq(3)", row).html(
            `<h6 style="color:#F04747;">ONGOING KEGIATAN</h6>`
          );
        }

        // if (data.pj_status == 3) {
        //   $("td:eq(3)", row).html(
        //     `<h6 style="color:#208BFF;">LPJ KEGIATAN</h6>`
        //   );
        // } else if (data.pj_status == 4) {
        //   $("td:eq(3)", row).html(`<h6 style="color:#55D177;">ALL DONE</h6>`);
        // }

        let dropdown2 =
          `<div class="btn-group">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="lpj1" data-id="` +
            data.id +
            `">Approve LPJ</button>
            <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="lpj2" data-id="` +
            data.id +
            `">Reject LPJ</button>
            </div>
          </div>`;

        if (data.count_pj > 0) {
          if (data.pj_status == 'null' || data.pj_status == '0') {
            $("td:eq(5)", row).html(dropdown2);
          } else if (data.pj_status == '1') {
            $("td:eq(5)", row).html('<span style="font-size:100%" class="badge badge-success">Approved</span');
          } else {
            $("td:eq(5)", row).html('<span style="font-size:100%" class="badge badge-danger">Rejected</span');
          }
        } else {
          $("td:eq(5)", row).html('Belum ada LPJ');
        }
      },
    });
  },
};
</script>
<style scoped>
</style>